import React from 'react';

const Footer = () => {


return (
	<div className = 'footer'>
		<p>Copyright WalTech Visualization - 2018</p>
	</div>
	);

}

export default Footer;